export const SEARCH_ID = '2353167';
export const SEARCH_WEB = '0';

export function getSearchParams(text: string): URLSearchParams {
  const searchParams = new URLSearchParams();
  searchParams.append('searchid', SEARCH_ID);
  searchParams.append('text', text);
  searchParams.append('web', SEARCH_WEB);
  return searchParams;
}
